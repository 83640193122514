import { ref } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import useNotifications from '@/composables/useNotifications'
import useJwt from '@/auth/jwt/useJwt'
import i18n from '@/libs/i18n'
import realmConnection from '@/views/habit/realm'

export default function useClientAdd() {
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { router } = useRouter()
  const { updateItem } = realmConnection()

  const client = ref({})

  function create(data) {
    const payload = {}
    // eslint-disable-next-line no-unused-expressions
    data.username.value ? (payload.username = data.username.value) : null
    // eslint-disable-next-line no-unused-expressions
    data.email.value ? (payload.email = data.email.value) : null
    // eslint-disable-next-line no-unused-expressions
    data.project.value ? (payload.project = data.project.value) : null
    const name = data.username.value
      ? data.username.value.replace(' ', '')
      : null
    const defaultPassword = '12345678'

    useJwt
      .register({ email: payload.email, password: defaultPassword, name })
      .then(async () => {
        try {
          const logUser = await useJwt.login({
            email: payload.email,
            password: defaultPassword,
          })

          const userPayload = {
            "custom_data.username": payload.name,
            "custom_data.project": payload.project,
            "custom_data.role": 'client',
            "custom_data.ability": [ { action: 'manage', subject: 'all' } ],
          }
          const action = { $set: userPayload }
          await updateItem({ collection: 'user', query: { id: logUser.id }, action })

          await useJwt.realm.allUsers[logUser.id].logOut()
          showSuccessMessage(i18n.t('message.client_added'))
          router.push({ name: 'apps-clients-list' })
        } catch (e) {
          showErrorMessage(e.message)
          router.push({ name: 'apps-clients-list' })
        }
      })
      .catch(error => {
        showErrorMessage(error.message)
        router.push({ name: 'apps-clients-list' })
      })
  }

  return { client, create }
}
