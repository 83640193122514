<template lang="pug">
div
  Form(:data="client", @submit="create")
</template>

<script>
import Form from '../shared/Form.vue'
// eslint-disable-next-line import/no-cycle
import useClientAdd from './useClientAdd'

export default {
  components: {
    Form,
  },

  setup() {
    const { client, create } = useClientAdd()

    return {
      client,
      create,
    }
  },
}
</script>
